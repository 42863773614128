import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { thunks as historiesThunks } from "../../app/store/histories-reducer";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { notifyInfo, notifySuccess, ToastBlock } from "../notify";

const ExpertRandom = () => {
  const [formData, setFormData] = useState({
    book: "", // Будет содержать ID выбранного учебника
    type: "",
  });

  const dispatch = useDispatch();
  const book_experts = useSelector(
    (state) => state.book_experts.book_experts.results
  );
  const experts = useSelector((state) => state.experts.experts.results);
  const histories = useSelector((state) => state.histories.histories.results);
  console.log(histories);
  const types = [
    "илимий",
    "илимий-педагогикалык",
    "антидискриминациялык жана гендердик",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const filteredExperts = useMemo(() => {
    if (!formData.book) return [];
    return experts.filter(
      (expert) => parseInt(expert.book) === parseInt(formData.book)
    );
  }, [formData, experts]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isExistingHistory(formData)) {
      notifyInfo("Такая запись уже существует в истории.");
      return;
    }
    if (filteredExperts.length > 0) {
      const randomExpert = selectRandomExpert(filteredExperts);
      addHistoryToServer(randomExpert);
      alert(`Выбранный эксперт: ${randomExpert.fullname}`);
    } else {
      notifyInfo("Нет экспертов, соответствующих выбранным фильтрам.");
    }
  };

  const isExistingHistory = (formData) => {
    return histories.some(
      (history) =>
        history._type === formData.type &&
        history.book === parseInt(formData.book)
    );
  };

  const selectRandomExpert = (filteredExperts) => {
    return filteredExperts[Math.floor(Math.random() * filteredExperts.length)];
  };

  const addHistoryToServer = (randomExpert) => {
    dispatch(
      historiesThunks.addHistory({
        winner: randomExpert.id,
        book: parseInt(formData.book),
        _type: formData.type,
      })
    );
    window.location.reload();
  };

  return (
    <div className="row no-print">
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 mt-5">
        <h1>{"Выбор эксперта рандомно"}</h1>

        <form
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          className="no-print"
        >
          {/* Выпадающий список для выбора учебника */}
          <div className="form-group">
            <label htmlFor="book">{"Учебник"}</label>
            <select
              id="book"
              name="book"
              value={formData.book}
              onChange={handleChange}
              className="form-control"
            >
              <option value="" disabled>
                Выберите учебник
              </option>
              {book_experts?.map((book) => (
                <option key={book.id} value={book.id}>
                  {book.name}
                </option>
              ))}
            </select>
          </div>

          {/* Выпадающий список для выбора типа */}
          <div className="form-group">
            <label htmlFor="type">{"Тип"}</label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="form-control"
            >
              <option value="" disabled>
                Выберите тип
              </option>
              {types.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <button
            className="btn btn-success"
            type="submit"
            style={{ marginTop: "10px" }}
          >
            {"Рандомный выбор"}
          </button>
        </form>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-4 mt-5">
        <p>
          {"Все попытки"}: {histories?.length}
        </p>
        <NavLink to="/history-list" className="page-link">
          Рандом
        </NavLink>
      </div>
    </div>
  );
};

export default ExpertRandom;
