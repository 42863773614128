import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'experts'

const fetchExpertsList = createAsyncThunk(
    `${namespace}/get`,
    async () => {
        let experts

        try {
            experts = await api.get('experts')
        } catch (error) {
            throw new Error(error)
        }

        return {experts}
    })

const {reducer} = createSlice({
    name: namespace,
    initialState: {
        experts: [],
    },
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchExpertsList.fulfilled, (state, action) => {
                state.experts = action.payload.experts
            })
})

const thunks = {fetchExpertsList}

export {reducer, thunks}
