
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'book-experts'

const fetchBookExpertsList = createAsyncThunk(
    `${namespace}/get`,
    async () => {
        let book_experts

        try {
            book_experts = await api.get('book-experts')
        } catch (error) {
            throw new Error(error)
        }

        return {book_experts}
    })

const {reducer} = createSlice({
    name: namespace,
    initialState: {
        book_experts: [],
    },
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchBookExpertsList.fulfilled, (state, action) => {
                state.book_experts = action.payload.book_experts
            })
})

const thunks = {fetchBookExpertsList}

export {reducer, thunks}
