import React from "react";
import "../snowfall/SnowFall.css";

const SnowFall = () => {
  return (
    <div className="snowFall-main">
      <div className="initial-snow">
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
      </div>
    </div>
  );
};

export default SnowFall;
