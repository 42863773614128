import React from "react";
import { Layout } from "../app/layout";
import ExpertRandom from "../components/expert-home/ExpertRandom";

export const RandomExpert = () => {
  return (
    <Layout>
      <ExpertRandom />
    </Layout>
  );
};
