import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faChartPie,
  faExchangeAlt,
  faHome,
  faList,
  faMoneyBill,
  faPhone,
  faQuestion,
  faStore,
  faTimes,
  faUser,
  faUserFriends,
  faVideo,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./menu.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import cn from "classnames";
import { ChangeLanguage } from "..";
import {
  isLabrarian,
  isDirector,
  isDistObserver,
  isLabrarianOrDirector,
  isJanyKitep,
  isCoordinator,
} from "../../lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { setShow } from "../../app/store/menu-reducer";

export const Menu = () => {
  const { t } = useTranslation();
  const show = useSelector((state) => state.menu.show);
  const dispatch = useDispatch();
  return (
    <nav
      className={cn(styles.mainMenu, {
        [styles.close]: show === false,
      })}
    >
      <div className={styles.icon}>
        <Button
          variant="outline-primary"
          className={styles.arrow}
          onClick={() => dispatch(setShow(false))}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <ul className={styles.mainMenuUl}>
        {/* <li>
          <NavLink exact to="/" className={styles.links} activeClassName={styles.active}>
            <FontAwesomeIcon icon={faHome} className="mx-3" />{t('menu.news')}
          </NavLink>
        </li> */}
        {isLabrarianOrDirector && (
          <>
            <li>
              <NavLink
                exact
                to="/school-events"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faCalendar} className="mx-3" />{" "}
                {t("menu.events")}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/school-news"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faCalendar} className="mx-3" />{" "}
                {t("menu.school_news")}
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            exact
            to="/profile"
            className={styles.links}
            activeClassName={styles.active}
          >
            <FontAwesomeIcon icon={faUser} className="mx-3" />
            Профиль
          </NavLink>
        </li>
        {isLabrarianOrDirector && (
          <>
            <li>
              <NavLink
                exact
                to="/classes"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faUserFriends} className="mx-3" />
                {t("menu.classes")}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/school-books"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faBook} className="mx-3" />{" "}
                {t("menu.school_books")}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/action-books"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faExchangeAlt} className="mx-3" />{" "}
                {t("menu.action_books")}
              </NavLink>
            </li>
          </>
        )}
        {isLabrarian && (
          <li>
            <NavLink
              exact
              to={{
                pathname:
                  "https://docs.google.com/document/d/1n06gJ2nHy5CZjN4Wr4dVECC_wvj0xFHhJk4QM1WCRiw/edit?usp=sharing",
              }}
              target="_blank"
              className={styles.links}
              activeClassName={styles.active}
            >
              <FontAwesomeIcon icon={faClipboard} className="mx-3" />{" "}
              {t("menu.usersGuide")}
            </NavLink>
          </li>
        )}
        {isDistObserver && (
          <li>
            <NavLink
              exact
              to={{
                pathname:
                  "https://docs.google.com/document/d/1RDo2kG2C6FxxGFwkGjpg48JAAWKy_oJoHB4A1NQFTAA/edit?usp=sharing",
              }}
              target="_blank"
              className={styles.links}
              activeClassName={styles.active}
            >
              <FontAwesomeIcon icon={faClipboard} className="mx-3" />{" "}
              {t("menu.usersGuide")}
            </NavLink>
          </li>
        )}
        {!isCoordinator && (
          <li>
            <NavLink
              exact
              to="/report"
              className={styles.links}
              activeClassName={styles.active}
            >
              <FontAwesomeIcon icon={faChartPie} className="mx-3" />{" "}
              {t("menu.report")}
            </NavLink>
          </li>
        )}
        {isCoordinator && (
          <li>
            <NavLink
              exact
              to="/expert-home"
              className={styles.links}
              activeClassName={styles.active}
            >
              <FontAwesomeIcon icon={faChartPie} className="mx-3" /> Рандом
            </NavLink>
          </li>
        )}
        {isCoordinator && (
          <li>
            <NavLink
              exact
              to="/history-list"
              className={styles.links}
              activeClassName={styles.active}
            >
              <FontAwesomeIcon icon={faChartPie} className="mx-3" /> История
            </NavLink>
          </li>
        )}

        {/* Временно скрыли */}
        {/* {isLabrarian && (
          <li>
            <NavLink
                  exact
                  to="/detail-balance"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                  <FontAwesomeIcon icon={faStore} className="mx-3" />
                  Сверка баланса
                </NavLink>
          </li>
          )} */}
        <li>
          {/* Заявка скрыта пока */}
          {/* {isLabrarian && (
                <NavLink
                  exact
                  to="/store"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                  <FontAwesomeIcon icon={faStore} className="mx-3" />
                  Заявка
                </NavLink>
          )}
          {(isDirector) && (
                <NavLink
                  exact
                  to="/store/orders"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                  <FontAwesomeIcon icon={faList} className="mx-3" /> Заявки
                </NavLink>
          )}
          {(isDistObserver) && (
                <NavLink
                  exact
                  to="/store/orders-shools"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                  <FontAwesomeIcon icon={faList} className="mx-3" /> Заявки
                </NavLink>
          )}
          {(isJanyKitep) && (
                <NavLink
                  exact
                  to="/store/general-orders-districts"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                  <FontAwesomeIcon icon={faList} className="mx-3" /> Заявки
                </NavLink>
          )} */}
          {isLabrarianOrDirector && (
            <>
              {/* <NavLink
                exact
                to="/payments"
                className={styles.links}
                activeClassName={styles.active}
              >
                <FontAwesomeIcon icon={faMoneyBill} className="mx-3" />{" "}
                {t("payment.title")}
              </NavLink> */}
            </>
          )}
        </li>
        {/* <li>
          <NavLink exact to="/questions" className={styles.links} activeClassName={styles.active}>
            <FontAwesomeIcon icon={faQuestion} className="mx-3" />  {t('menu.questions')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink exact to="/videos" className={styles.links} activeClassName={styles.active}>
            <FontAwesomeIcon icon={faVideo} className="mx-3" />  {t('menu.videos')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink exact to="/contacts" className={styles.links} activeClassName={styles.active}>
            <FontAwesomeIcon icon={faPhone} className="mx-3" />  {t('menu.contacts')}
          </NavLink>
        </li> */}
        <li></li>
        <li>
          <div className={styles.lang}>
            <ChangeLanguage />
          </div>
        </li>
      </ul>
    </nav>
  );
};
