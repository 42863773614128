import React, { useRef, useState } from "react";
import { Layout } from "../app/layout";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import styles from "../components/pdf-header/pdf-header.module.css";
import { notifySuccess } from "../components/notify";

export const Historylist = () => {
  const { results, count } = useSelector((state) => state.histories.histories);
  const printRef = useRef(null);
  const bookExperts = useSelector(
    (state) => state.book_experts.book_experts.results
  );
  const experts = useSelector((state) => state.experts.experts.results);
  const [expandedRow, setExpandedRow] = useState(null); // ID строки, которая сейчас раскрыта

  const isPaginated = count > results?.length; // Проверка на пагинацию
  const pageSize = 10; // Например, 10 элементов на странице
  const currentPage = 1; // Нужно передать текущую страницу (для примера 1)

  const getExpertById = (id) => experts?.find((expert) => expert.id === id);
  const getBookById = (id) => bookExperts?.find((book) => book.id === id);

  const toggleRow = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            {"История"}
          </li>
        </ol>
      </nav>

      <div className="row">
        <div className="col list-group">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>{"ФИО"}</th>
                <th>{"Книга"}</th>
                <th>{"Дата рандома"}</th>
                <th>{"Подробнее"}</th>
              </tr>
            </thead>
            <tbody>
              {results?.map((object, index) => {
                const expert = getExpertById(object.winner); // Получаем эксперта
                const book = getBookById(object.book); // Получаем книгу
                const isExpanded = expandedRow === object.id;

                return (
                  <React.Fragment key={object.id}>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{expert ? expert.fullname : "Unknown"}</td>{" "}
                      {/* Имя эксперта */}
                      <td>{book ? book.name : "Unknown"}</td>{" "}
                      {/* Название книги */}
                      <td>
                        {new Date(object.created).toLocaleDateString("ru-RU", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}{" "}
                        {new Date(object.created).toLocaleTimeString("ru-RU", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => toggleRow(object.id)}
                        >
                          {isExpanded ? "Скрыть" : "Подробнее"}
                        </button>
                      </td>
                    </tr>
                    {isExpanded && (
                      <>
                        <tr>
                          <td colSpan="5">
                            <div
                              style={{ textAlign: "start" }}
                              className="p-3 bg-light border"
                            >
                              <h5>Информация о победителе:</h5>
                              <p>
                                <strong>Эксперт:</strong>{" "}
                                {expert ? expert.fullname : "Unknown"}
                              </p>
                              <p>
                                <strong>Книга:</strong>{" "}
                                {book ? book.name : "Unknown"}
                              </p>
                              <p>
                                <strong>Дата выбора рандома:</strong>{" "}
                                {new Date(object.created).toLocaleString(
                                  "ru-RU"
                                )}
                              </p>
                              <p>
                                <strong>Тип:</strong>
                                {object._type}
                              </p>
                              <p>
                                <button
                                  onClick={handlePrint}
                                  className="btn btn-primary"
                                >
                                  Скачать
                                </button>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <div>
                          <table className={styles.print} ref={printRef}>
                            <div className="print-container">
                              <div className={styles.title}>
                                <h3>Экспертти Тандоо </h3>
                              </div>
                              <div className="d-flex mt-5">
                                <div className="mr-auto">
                                  <p> Бишкек ш. </p>
                                </div>
                                <div className="ml-auto">
                                  <span> ж.</span>
                                </div>
                              </div>
                              <p className="mt-3"></p>
                              <p className="mt-2">
                                Окуу китебинин аталышы:{" "}
                                {book ? book.name : "Unknown"}
                              </p>
                              <p className="mt-2">
                                окуу усулдук комплексине {object._type}{" "}
                                экспертиза жүргүзүү үчүн эксперт катары тандалды
                              </p>
                              <p className="mt-2">Комиссия:</p>
                              <p className="mt-2"> ________________</p>
                              <p className="mt-2"> _______________</p>
                              <p className="mt-2">Көзөмөлдөөчүлөр:</p>
                              <p className="mt-2">
                                ______________________________________________
                              </p>
                              <p className="mt-2">
                                ______________________________________________
                              </p>
                              <p style={{ marginTop: 150 }}>
                                Документтин аныктыгын төмөнкү QR код аркылуу
                                текшерип алыңыз.
                              </p>
                              <img
                                id="qrcode"
                                src={object.document}
                                style={{ marginTop: 10, width: 60 }}
                              />
                            </div>
                            <div id="pdfdiv"></div>
                          </table>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>

          {/* Пагинация */}
          {isPaginated && (
            <ul className="pagination mt-5">
              {/* Предыдущая страница */}
              {currentPage > 1 && (
                <li className="page-item">
                  <a className="page-link" href={`?page=${currentPage - 1}`}>
                    &laquo;
                  </a>
                </li>
              )}

              {/* Страницы */}
              {[...Array(Math.ceil(count / pageSize)).keys()].map((pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    currentPage === pageNum + 1 ? "active" : ""
                  }`}
                >
                  <a className="page-link" href={`?page=${pageNum + 1}`}>
                    {pageNum + 1}
                  </a>
                </li>
              ))}

              {/* Следующая страница */}
              {currentPage < Math.ceil(count / pageSize) && (
                <li className="page-item">
                  <a className="page-link" href={`?page=${currentPage + 1}`}>
                    &raquo;
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </Layout>
  );
};
