import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../service/old-api';
import { notifySuccess, ToastBlock } from '../../components/notify';

const namespace = 'histories';

// Fetch истории
const fetchHistories = createAsyncThunk(
    `${namespace}/get`,
    async () => {
        let histories;

        try {
            histories = await api.get('histories');
        } catch (error) {
            throw new Error(error);
        }

        return { histories };
    }
);

// Добавление новой истории
const addHistory = createAsyncThunk(
    `${namespace}/add`,
    async (newHistoryData) => {
        let newHistory;
        try {
            // Отправка POST запроса для добавления новой записи в историю
            newHistory = await api.post('histories',  {body: newHistoryData});
            await dispatch(fetchHistories())
        } catch (error) {
            throw new Error(error);
        }

        return { newHistory }; // Вернем добавленную запись
    }
);

const { reducer } = createSlice({
    name: namespace,
    initialState: {
        histories: [],
    },
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchHistories.fulfilled, (state, action) => {
                state.histories = action.payload.histories;
            })
            .addCase(addHistory.fulfilled, (state, action) => {
                // Обновляем список историй, добавляя новую запись
                state.histories.push(action.payload.newHistory);
            }),
});

const thunks = { fetchHistories, addHistory };

export { reducer, thunks };
